import React from "react"
import Anchor from "../../core/components/anchor"
import Button from "../../client/compositions/button"
import withProps from "../../core/composers/props"
import withParallax2 from "../../core/composers/parallax2"
import by from "../../core/functions/by"

import portfolioItems from "../../client/data/portfolio.json"
import products from "../../client/data/products.json"

const Parallax = withParallax2(({ children }) => (
  <div className="parallax">{children}</div>
))

/** Tagline component */
function TaglineComponent(props) {
  const { children, ...passthruProps } = props

  const oXs = [0, 0, 0]
  const xs = [85, -85, 85]

  return (
    <section
      className={`tagline ${children ? "with-children" : ""}`}
      {...passthruProps}
    >
      {children ? (
        React.Children.map(children, (child, i) => (
          <div key={i}>
            <Parallax x={xs[i]} oX={oXs[i]}>
              {child}
            </Parallax>
          </div>
        ))
      ) : (
        <>
          <div>
            <Parallax x={50} oX={oXs[0]}>
              Good wine
            </Parallax>
          </div>
          <div>
            <Parallax x={-50} oX={oXs[1]}>
              deserves
            </Parallax>
          </div>
          <div>
            <Parallax x={50} oX={oXs[2]}>
              to be seen
            </Parallax>
          </div>
        </>
      )}
    </section>
  )
}

/**
 * Compose
 */
const Tagline = TaglineComponent

/**
 * Products Menu Tagline
 */
export const ProductsTaglineMenu = withProps(Tagline, ({ productSlug }) => {
  const current = products.findIndex(by("slug", productSlug))
  const first = 0
  const last = products.length - 1

  const prev = current > first ? current - 1 : last
  const next = current < last ? current + 1 : first

  const { name: prevName, url: prevURL } = products[prev]
  const { name: nextName, url: nextURL } = products[next]

  return {
    children: [
      <Button to={prevURL} image="icons/arrow-left.svg" className="previous">
        {prevName}
      </Button>,
      <Button to={nextURL} image="icons/arrow-right.svg" className="next">
        {nextName}
      </Button>,
      <Anchor to="/products">Products</Anchor>,
    ],
  }
})

/**
 * Portfolio Menu Tagline
 */
export const PortfolioTaglineMenu = withProps(Tagline, ({ portfolioSlug }) => {
  const current = portfolioItems.findIndex(by("slug", portfolioSlug))
  const first = 0
  const last = portfolioItems.length - 1

  const prev = current > first ? current - 1 : last
  const next = current < last ? current + 1 : first

  const { url: prevURL } = portfolioItems[prev]
  const { url: nextURL } = portfolioItems[next]

  return {
    children: [
      <Button to={prevURL} image="icons/arrow-left.svg" className="previous">
        Previous
      </Button>,
      <Button to={nextURL} image="icons/arrow-right.svg" className="next">
        Next
      </Button>,
      <Anchor to="/portfolio">Portfolio</Anchor>,
    ],
  }
})

export default Tagline
