// @flow

// react
import * as React from "react"
import { lazyload } from "react-lazyload"
import classNames from "react-css-module-classnames"

// gatsby
import Img from "gatsby-image"

// <Decoration />
type Props = {
  /** gatsby fluid prop */
  fluid?: Object,
  /** match item in images array to use as image */
  image?: string | RegExp,
  /** size to use */
  size?: number,
  /** Component data, perhaps provided by withData() */
  data?: {
    /** Image src image data */
    images?: {
      [size: number]: Array<{
        relativePath: string,
        src?: string,
        srcSet?: string,
        ...
      }>,
    },
  },
  /** Alternative text */
  alt: string,
  /** Custom class for root element */
  className?: string,
}

/**
 * A gatsby image that is not a necessary part of the page content.
 *
 * ## CSS Classes
 * |--------------------|------------------------------------------------------|
 * | class              | Purpose                                              |
 * |--------------------|------------------------------------------------------|
 * | .decoration-fluid  | Root element                                         |
 * |--------------------|------------------------------------------------------|
 */
class DecorationFluidComponent extends React.Component<Props> {
  // react methods

  render() {
    let {
      fluid,
      image,
      size,
      data,
      alt,
      src,
      srcSet,
      className,
      ...decorationProps
    } = this.props

    let imageProps = Object.assign({}, decorationProps)

    if (!fluid && data) {
      let { images } = data || {}

      // if data is a list of possible images
      // get image which matches "image" prop
      if (image && images instanceof Array) {
        let matched = images.find((img) =>
          img.relativePath.match(
            image instanceof RegExp ? image : new RegExp(image)
          )
        )

        if (matched) fluid = matched
      }
    }

    // set error if no data
    if (image && !fluid) {
      console.error(`${image} not found!`)
    }

    return (
      <div {...classNames("decoration-fluid").plus(className)}>
        {fluid && <Img fluid={fluid} {...imageProps} />}
      </div>
    )
  }
}

/**
 * Exports
 */
export default DecorationFluidComponent
