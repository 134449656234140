/**
 * useImagesFluidWithWebpTracedSVG
 *
 * Query image sharp fluid images.
 */

/**
 * Imports
 */

// gatsby
import { useStaticQuery, graphql } from "gatsby"

/**
 * Exports
 */
function useImagesFluid1920() {
  const data = useStaticQuery(graphql`
    query ImagesFluid1920 {
      allImageSharp {
        edges {
          node {
            fluid(
              maxWidth: 1920
              srcSetBreakpoints: [480, 1024, 1920]
              quality: 100
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            parent {
              ... on File {
                relativePath
              }
            }
          }
        }
      }
    }
  `)

  return {
    imagesFluid1920: data.allImageSharp.edges.map(({ node }) =>
      Object.assign({}, node.fluid, node.parent)
    ),
  }
}

/**
 * Exports
 */
export default useImagesFluid1920
