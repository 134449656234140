// @flow

// react
import * as React from "react"
import { lazyload } from "react-lazyload"
import classNames from "react-css-module-classnames"

// <Row />
type Props = {
  /** Content */
  background: any,
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Row
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .row             | Root element                                           |
 * | .inner           | Inner element                                          |
 * | .background      | Background element                                     |
 * |------------------|--------------------------------------------------------|
 */
@lazyload({
  height: "100vh",
  once: true,
  offset: 700,
})
class Row extends React.Component<Props> {
  static defaultProps = {}

  // react methods

  render() {
    let { background, children, className, ...rowProps } = this.props

    return (
      <section {...rowProps} {...classNames("row").plus(className)}>
        {background && (
          <div {...classNames("background")}>
            {background === true ? "" : background}
          </div>
        )}
        {children && <div {...classNames("inner")}>{children}</div>}
      </section>
    )
  }
}

/**
 * Exports
 */
export default Row
