import DecorationFluidComponent from "../../core/components/decoration-fluid"
import withData from "../../core/composers/data"
import useImagesFluid1920 from "../../client/queries/images-fluid-1920"

/**
 * Compose
 */
const DecorationFluid = withData(
  DecorationFluidComponent,
  ({ imagesFluid1920 }) => ({
    images: imagesFluid1920,
  }),
  useImagesFluid1920
)

export default DecorationFluid
