// @flow

import React from "react"
import gsap from "gsap"
import Events from "../../core/classes/events"
import getIdleCallback from "../../core/compatibility/get-idle-callback"
import getScrollHeight from "../../core/compatibility/get-scroll-height"
import getScrollTop from "../../core/compatibility/get-scroll-top"

type Props = {
  x: number,
  y: number,
}

// style writers
const transition = (duration = 0) => `transform ${duration}s ease-out`
const transform = (x = 0, y = 0, z = 0) =>
  `translateX(${x}px) translateY(${y}px) translateZ(${z}px)`

/**
 * withParallax2()
 */
const withParallax2 = function (WrappedComponent) {
  // must be function component to use React Hooks
  return class extends React.Component<Props> {
    static defaultProps = {
      x: 0,
      y: 0,
    }

    events = new Events()

    wrapperElement: ?HTMLDivElement

    setPosition(x, y, z) {
      const { wrapperElement } = this

      if (!wrapperElement) {
        return
      }

      wrapperElement.style.transform = transform(x, y, z)
    }

    handleResize(event?: Event) {
      const { wrapperElement } = this

      if (!wrapperElement) {
        return
      }

      this.handleScroll(event)
    }

    handleScroll(event: Event) {
      const { wrapperElement } = this
      let { x, y, z, oX, oY, oZ } = this.props

      const scrollTop = getScrollTop()
      const scrollHeight = getScrollHeight()

      const windowHeight = window.innerHeight
      let { y: elementPosition } = wrapperElement.getBoundingClientRect()

      if (!wrapperElement) {
        return
      }

      let current = Math.ceil(scrollTop + windowHeight)
      let min = scrollHeight - windowHeight
      let max = scrollHeight

      if (current < min) current = min
      if (current > max) current = max

      if (typeof x !== "number") x = 0
      if (typeof y !== "number") y = 0
      if (typeof z !== "number") z = 0
      if (typeof oX !== "number") oX = 0
      if (typeof oY !== "number") oY = 0
      if (typeof oZ !== "number") oZ = 0

      const ratio = 1 - (current - min) / (max - min)

      this.setPosition(oX + x * ratio, oY + y * ratio, oZ + z * ratio)
    }

    componentDidMount() {
      const { wrapperElement, events } = this

      if (!wrapperElement) {
        return
      }

      // animate wrapper position
      wrapperElement.style.transition = transition(0.85)
      wrapperElement.style.transform = transform(0, 0)
      wrapperElement.style.willChange = `transform`

      // listen to scroll
      const idleCallback = getIdleCallback()
      idleCallback(this.handleResize.bind(this))

      events.listen("scroll", this.handleScroll.bind(this), window, {
        passive: true,
      })

      events.listen("resize", this.handleResize.bind(this), window, {
        passive: true,
      })

      events.listen(
        "afterRouteUpdate.gatsby",
        this.handleResize.bind(this),
        window,
        {
          passive: true,
        }
      )
    }

    componentWillUnmount() {
      const { events } = this

      events.cleanUp()
    }

    render() {
      // get data from props
      let { x, y, ...passthruProps } = this.props

      // add to wrapped component
      return (
        <div
          ref={(wrapperElement) => (this.wrapperElement = wrapperElement)}
          className="parallax-wrapper"
        >
          <WrappedComponent {...passthruProps} />
        </div>
      )
    }
  }
}

/**
 * Exports
 */
export default withParallax2
