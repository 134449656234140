// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// <Block />
type Props = {
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Block
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .block           | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class Block extends React.Component<Props> {
  static defaultProps = {}

  // react methods

  render() {
    let { children, className, ...blockProps } = this.props

    return (
      <div {...blockProps} {...classNames("block").plus(className)}>
        {children}
      </div>
    )
  }
}

/**
 * Exports
 */
export default Block
